import React, { useEffect, useRef } from 'react';
import './Homepage.css';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import Marquee from "react-fast-marquee";

import ecomm from '../../Images/ecomm.png';
import cloud from '../../Images/cloud.png';
import graphic from '../../Images/graphic.png';
import blockchain from '../../Images/blockchain.png';
import { useNavigate } from 'react-router-dom';

import { TweenMax, Power3 } from 'gsap';

import GoToTop from '../ScrollToTop';



function Homepage() {

    const navigate = useNavigate();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    // const scrollRef = useRef(null);

    // useEffect(() => {
    //     const scroll = new LocomotiveScroll({
    //         el: scrollRef.current,
    //         smooth: true,
    //     });

    //     return () => {
    //         scroll.destroy();
    //     }
    // }, []);


    const userreviewdata = [
        {
            name: 'Hiren Patel',
            review: 'It was good, I feel like a home, quick response, time to time follow up, received good support for content creation, and super cool website that attract the customer.'
        },
        {
            name: 'Hiren Patel',
            review: 'It was good, I feel like a home, quick response, time to time follow up, received good support for content creation, and super cool website that attract the customer,.'
        }
    ]


    let title1 = useRef(null);
    let title2 = useRef(null);
    let title3 = useRef(null);

    useEffect(() => {
        console.log(title1);
        TweenMax.to(
            title1,
            1,
            {
                opacity: 1,
                y: -40,
                ease: Power3.ease
            }
        )
        TweenMax.to(
            title2,
            1,
            {
                opacity: 1,
                y: -40,
                ease: Power3.ease,
                delay: 0.3,
            }
        )
        TweenMax.to(
            title3,
            1,
            {
                opacity: 1,
                y: -40,
                ease: Power3.ease,
                delay: 0.6
            }
        )

    }, [])





    return (



        // <div ref={scrollRef} >
        <div id='smooth-wrapper'>


            <section className='homepage_section_one' id='smooth-content'  >
                <div>
                    <div data-scroll className='letter_div'>
                        <div ref={el => { title1 = el }} style={{ opacity: '0' }}>
                            <span className='title_grey'>inspiring<span className='title_red'> ideas<span className='title_grey'> to</span></span></span>
                        </div>

                        <div ref={el => { title2 = el }} style={{ opacity: '0' }}>
                            <span className='title_darkgrey'>make<span className='title_darkgrey2'> dreams<span className='title_red'> happen</span></span></span>
                        </div>

                        <div ref={el => { title3 = el }} style={{ opacity: '0' }}>
                            <span className='title_darkgrey3'>with the<span className='title_black'> Digital push.</span></span>
                        </div>

                    </div>
                    <div className='homepage_section_one_second_div'>
                        <div className='homepage_schdule_title'
                            onClick={() => {
                                navigate('/contact')
                            }}>SCHEDULE CALL<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)' }}></i></div>
                        <div className='homepage_schdule_title2'
                            onClick={() => {
                                navigate('/contact')
                            }}>HOW CAN WE HELP YOU<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)' }}></i></div>
                    </div>
                </div>
            </section>


            <section className='homepage_section_two' data-scroll>
                <span className='homepage_sec_two_check_title'>CHECK US OUT -</span>
                <div className=' section_two_center_div'>
                    <div className='homepage_sec_two_left_side ' >
                        <span>What makes <br></br> us Unique ?</span>
                    </div>
                    <div className='homepage_sec_two_right_side mx-3'>
                        <span>A bold and impactful IT<br />Solutions agency for<br />ambitious brands.</span>
                        <div>

                            <Accordion
                                className='homepage_two_accordian'
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        expanded === "panel1" ? <i class="fa-solid fa-minus fa-xl" style={{ color: 'white' }}></i> : <i class="fa-solid fa-plus fa-xl" style={{ color: 'white' }}></i>
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className='homepage_two_acc_title' sx={{ width: "50%", flexShrink: 0 }}>
                                        Help Desk Delivery
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='homepage_two_acc_paragraph'>
                                        We readily provide you with a help desk delivery that is able to enable smooth conversation between you and us a place where your concern is brought to satisfaction.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                className='homepage_two_accordian'
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        expanded === "panel2" ? <i class="fa-solid fa-minus fa-xl" style={{ color: 'white' }}></i> : <i class="fa-solid fa-plus fa-xl" style={{ color: 'white' }}></i>
                                    }
                                    aria-controls="panel2bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className='homepage_two_acc_title' sx={{ width: "50%", flexShrink: 0 }}>
                                        Reliability
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='homepage_two_acc_paragraph'>
                                        Everyone wonders about the devices that they use and how long would they last. Well, with us we make sure that our solutions are reliable for you and that you are able to make the best of it.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                className='homepage_two_accordian'
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        expanded === "panel3" ? <i class="fa-solid fa-minus fa-xl" style={{ color: 'white' }}></i> : <i class="fa-solid fa-plus fa-xl" style={{ color: 'white' }}></i>
                                    }
                                    aria-controls="panel3bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className='homepage_two_acc_title' sx={{ width: "50%", flexShrink: 0 }}>
                                        On Time Delivery
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='homepage_two_acc_paragraph'>
                                        Time is important for everyone and in this fast-paced world; we take into consideration of the time factor and provide you with your requirements on time with our methods of delivery so you are never late for your task.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion
                                className='homepage_two_accordian'
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        expanded === "panel4" ? <i class="fa-solid fa-minus fa-xl" style={{ color: 'white' }}></i> : <i class="fa-solid fa-plus fa-xl" style={{ color: 'white' }}></i>
                                    }
                                    aria-controls="panel4bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className='homepage_two_acc_title' sx={{ width: "50%", flexShrink: 0 }}>
                                        Structured Solutions
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className='homepage_two_acc_paragraph'>
                                        Strategy is the key to success, we ensure that we are able to provide strategic solutions to you that are well organized and are able to help draw outcomes that you expect.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        </div>


                        <div className='home_two_contact_title'
                            onClick={() => {
                                navigate('/aboutus')
                            }}>ABOUT US<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)',marginLeft:'5px' }}></i></div>
                    </div>
                </div >
                <div className='homepage_sec_two_social_div'>
                    <i class="fa-brands fa-linkedin fa-2xl" style={{ color: 'white' }}></i>
                    <i class="fa-brands fa-facebook fa-2xl" style={{ color: 'white' }}></i>
                    <i class="fa-brands fa-instagram fa-2xl" style={{ color: 'white' }}></i>
                </div>
            </section >

            <section className='homepage_section_three'>
                <div className='gg'>
                    <div className='homepage_section_three_sec_div'>
                        <div className='three_sec_left'>
                            <div>
                                <img className='section_three_img' src={ecomm} alt='ecom'></img>
                                <div className='section_three_img_title'>E-commerce Solutions</div>
                            </div>
                            <div className='three_block_div'>
                                <img className='section_three_img' src={blockchain} alt='blockchain'></img>
                                <div className='section_three_img_title'>Block Chain</div>
                            </div>
                            <div className='three_sec_right_view_title2' onClick={() => {
                                navigate('/services')
                            }}>VIEW ALL<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)' }}></i></div>
                        </div>
                        <div className='three_sec_right'>
                            <div>
                                <div>
                                    <div className='three_sec_right_title'>Our</div>
                                    <div className='three_sec_right_title'>Service</div>
                                    <div className='three_sec_right_view_title'
                                        onClick={() => {
                                            navigate('/services')
                                        }}>VIEW ALL<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)' }}></i></div>
                                </div>
                                <div className='three_cloud_div'>
                                    <img className='section_three_img' src={cloud} alt='cloud computing'></img>
                                    <div className='section_three_img_title'>Cloud Computing</div>
                                </div>
                                <div className='three_ecom_div'>
                                    <img className='section_three_img' src={graphic} alt='graphic design'></img>
                                    <div className='section_three_img_title'>E-commerce Solutions</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >
            </section >

            <section className='homepage_section_four'>
                <div className='homepage_sec_four_second'>
                    <div className='row text-center' style={{ position: 'relative' }}>
                        <div className='home_four_too_title'>TOO LEGIT-</div>
                        <div className='col-12 col-sm-6 col-lg-6 text-start'>
                            <div className='home_four_our_title'>Directly Involved In <br /> the<span> Process</span></div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-6 text-start'>
                            <div className='home_four_right_title'>A bold and impactful IT Solutions agency for ambitious brands.</div>
                            <p className='home_four_right_paragraph'>Not sure about having multiple intermediaries in your work? You shouldn’t worry about that, we’ve got you covered. Our platform provides you support in-house, no outside business, ready to tackle and bring solutions.</p>
                            <p className='home_four_right_paragraph'>Want to know how we do it? We have designed a specialised approach to the entire process where multiple steps are involved that enable us to generate effective results for your concerns.</p>
                            <div className='homepage_four_contact_title' onClick={() => {
                                navigate('/contact')
                            }}>CONTACT<i class="fa-solid fa-arrow-up" style={{ color: '#d31a5b', transform: 'rotate(45deg)' }}></i></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='homepage_fifth_section'>
                <Marquee speed={400}  >
                    <span className='home_fifth_text_name'>Integration</span>
                    <span className='home_fifth_text_name'>Scalability</span>
                    <span className='home_fifth_text_name'>User</span>
                </Marquee>
                <Marquee direction='right' speed={400}>
                    <span className='home_fifth_text_name2'>User Friendly</span>
                    <span className='home_fifth_text_name2'>Process Oriented</span>
                </Marquee>





            </section>

            <section className='homepage_section_six'>
                <div>
                    <div className='homepage_six_title'>WHAT OUR CLIENTS SAY</div>
                    <Splide
                        className="review_section_div"
                        hasTrack={false}
                        aria-label="..."
                        options={{
                            rewind: true,
                            width: "1000px",
                            height: "260px",
                            gap: "1rem",
                            direction: "ttb",
                            breakpoints: {
                                1024: {
                                    height: "240px",
                                    width: 'auto'
                                },
                                768: {
                                    height: '240px',
                                    width: 'auto'
                                },
                                640: {
                                    width: 'auto',
                                    height: '250px'
                                }
                            }
                        }}

                    >
                        <SplideTrack>



                            {userreviewdata.map((data) => (
                                <SplideSlide>
                                    <div>
                                        <div className='homepage_six_user_review_para'>
                                            {data.review}
                                        </div>
                                        <div className='homepage_six_user_name'>
                                            {data.name}
                                        </div>
                                    </div>
                                </SplideSlide>
                            ))}
                        </SplideTrack>
                        <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev">
                                <i class="fa-sharp fa-solid fa-arrow-up fa-2xl"></i>
                            </button>
                            <button className="splide__arrow splide__arrow--next">
                                <i class="fa-solid fa-arrow-down fa-2xl mt-4"></i>
                            </button>
                        </div>
                    </Splide>
                </div>
            </section>

            {/* <div className='Homepage_reel_div rotate'>
                Reel
            </div> */}

            {/* <Footer /> */}
            <GoToTop />
        </div >


    )
}


export default Homepage