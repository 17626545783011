import React from 'react'

function Canandrefund() {
  return (
    <div className='container'>
      <div class="content-container">
        <p class="content-head page_title">Cancellation &amp; Refund Policy</p>
        <div class="content-seprater"></div>
        <p class="updated-date">Last updated on Apr 30th 2024</p>
        <p class="content-text">No cancellations &amp; Refunds are entertained</p>
      </div>
    </div>
  )
}

export default Canandrefund