import React from 'react';
import './Services.css';
import GoToTop from '../ScrollToTop';
import { TweenMax, Power3 } from 'gsap';
import { useEffect, useRef } from 'react';

function Services() {

  const servicesdata = [
    {
      title: 'E-Commerce Solutions',
      discription: 'Developing a brand with E-commerce is quintessential, and being a company of the modern era, we’ve gathered tools and created setups that help you accumulate your required resources and establish a full-fledged E-Commerce platform to be active in the generation of growth.'
    },
    {
      title: 'Cloud Computing',
      discription: 'Many companies now refer to cloud computing, which is one of the leading ways to have dynamic control over their business data and functions. If you’re looking to get your hands on cloud computing, we’re ready to help you with our tools that help you do so.'
    },
    {
      title: 'Block Chain',
      discription: 'Over the last couple of years block chain has seen a proliferating interest and heard from a lot of people. The new method of conducting effortless network transactions has taken over the minds of many people. We serve expert solutions in this sector that help you work on your integration.'
    },
    {
      title: 'Graphic Design',
      discription: 'Our leading teams of graphic designers are able to generate unique ideas that help your business remain creative and you are able to draw attention using attractive methods that we have in store in accordance with your business.'
    },
    {
      title: 'Branding',
      discription: 'Getting known is the key aspect of any business, branding plays an important role in setting the image of your company in the minds of the people. We have expertise in brand creation that is able to set a unique picture of your brand in the minds of the public that actively participates and augments the features of your business.'
    },
    {
      title: 'Social Media Marketing',
      discription: 'Adapting to social media has been the cardinal move in the last few years, we help you stand out on the media platforms that are surfed by many. We have an entirely dedicated team that works in favour of developing the marketing base according to your requirement and stand out in the social aspect.'
    },
    {
      title: 'Website Development',
      discription: 'A website promotes the ideas of a business, a base that helps promote a business and helps connect people from various locations. We provide web development solution that helps to integrate your website with your business and stand out from the rest.'
    },
    {
      title: 'App Development',
      discription: 'A personalized app is an indication of a business that wants to stay connected with its consumer base. Do you want to build that strong base of bonds and draw active attention to your consumer base? We’ve got you covered with our team that works with you to build your strong customer base.'
    },
    {
      title: 'PC Building',
      discription: 'Looking for expert PC builders? We are your answer. Our experienced team works well with computer building that helps you complete your task. Our force is knowledgeable in choosing the right parts for your PC according to your usage and building it for you.'
    },
    {
      title: 'IT Hardware',
      discription: 'Want help with advanced hardware that you would want to integrate with your project? We have an access to a wide range of hardware information that we can help suggest and work with in order to satisfy your requirement and help function well with your organization.'
    },
    {
      title: 'White label E-commerce Solution',
      discription: 'White-label E-commerce is a more cost-effective method of E-Commerce that we have an experience and sheer knowledge about. Working with this method is ever so simple for us and is able to provide fruitful results.'
    },
    {
      title: 'White label Booking Solution',
      discription: 'We have built our business on a strong base and with the expertise of our team, thus we can make sure that when we work together we will ensure that your requirements are satisfied and that collaborative growth can be brought.'
    },

  ]


  let title1 = useRef(null);
  let title2 = useRef(null);
  let title3 = useRef(null);

  useEffect(() => {
    console.log(title1);
    TweenMax.to(
      title1,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease
      }
    )
    TweenMax.to(
      title2,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease,
        delay: 0.5,
      }
    )
    TweenMax.to(
      title3,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease,
        delay: 1.0
      }
    )

  }, [])




  return (
    <div className='services_main_div'>
      <div>

        <section className='services_ourservice_main_div'>
          <div className='text-center'>
            <div className='services_our_services_title' ref={el => { title1 = el }} style={{ opacity: '0' }}>OUR SERVICES</div>
            <div className='service_full_title' ref={el => { title2 = el }} style={{ opacity: '0' }}>A full Serving of</div>
            <div className='service_title' ref={el => { title3 = el }} style={{ opacity: '0' }}>Services</div>
          </div>
        </section>

        <section className='services_second_Section'>
          <div className='services_second_first_div'>
            {servicesdata.map((data) => (
              <div className='our_services_div '>
                <div className=' services_titles'>{data.title}</div>
                <div className=' services_discription'>{data.discription}</div>
              </div>
            ))}
          </div>

        </section>
      </div>
      <GoToTop />
    </div>
  )
}

export default Services