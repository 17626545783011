import { useNavigate } from "react-router-dom";
import "./Headertwo.css";
import { useState } from "react";
import mainlogowithtitle from '../Images/mainlogo.png';


const navs = [
    <div>
        {/* <nav>
            <a style={{ animationDelay: "0.8s" }}>Profile</a>
            <a style={{ animationDelay: "0.9s" }}>Skills</a>
            <a style={{ animationDelay: "1s" }}>Education</a>
        </nav> */}

    </div>
];


export const Headertwo = () => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    const openClass = isOpen ? "open" : "";


    let navigate = useNavigate()


    return (
        <div className="nav_main_div">



            <img className='header_main_logo' alt='mainlogowithtitle' src={mainlogowithtitle} onClick={() => { navigate('/') }}></img>

            <div>



                <div className={`background ${openClass}`}></div>
                <div className={!isOpen ? "herader_menu_title" : "herader_menu_title2"}>Menu</div>
                <button className={`burger ${openClass}`} onClick={toggleMenu}></button>
                <div className={`menu ${openClass}`}>
                    <div>
                        <nav className=''>
                        <a style={{ animationDelay: '0.2s' }} onClick={() => {
                                setIsOpen(false)
                                navigate('/')
                            }}>Home</a>
                            <a style={{ animationDelay: '0.2s' }} onClick={() => {
                                setIsOpen(false)
                                navigate('/services')
                            }}>Services</a>
                            <a style={{ animationDelay: '0.3s' }} onClick={() => {
                                setIsOpen(false)
                                navigate('/aboutus')
                            }}>About us</a>
                            <a style={{ animationDelay: '0.4s' }} onClick={() => {
                                setIsOpen(false)
                                navigate('/contact')
                            }}>Contact</a>
                        </nav>

                    </div>
                </div>

            </div>
        </div>
    );
};


export default Headertwo;