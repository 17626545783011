import React from 'react'

function Shippinganddel() {
    return (
        <div className='container'>
            <div class="content-container">
                <p class="content-head page_title">Shipping &amp; Delivery Policy</p>
                <div class="content-seprater"></div>
                <p class="updated-date">Last updated on Apr 30th 2024</p>
                <p class="content-text">Shipping is not applicable for business.</p>
            </div>
        </div>
    )
}

export default Shippinganddel