import React from 'react';
import './Aboutus.css';
import aboutimageone from '../../Images/aboutimageone.png';
// import mainlogowithtitle from '../../Images/mainlogowithtitle.png';
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom';
import mainlogowithtitle from '../../Images/mainlogo.png';
import GoToTop from '../ScrollToTop';
import { TweenMax, Power3 } from 'gsap';
import { useEffect, useRef } from 'react';

function Aboutus() {

  let navigate = useNavigate();

  let title1 = useRef(null);
  let title2 = useRef(null);
  let title3 = useRef(null);

  useEffect(() => {
    console.log(title1);
    TweenMax.to(
      title1,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease
      }
    )
    TweenMax.to(
      title2,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease,
        delay: 0.5,
      }
    )
    TweenMax.to(
      title3,
      1,
      {
        opacity: 1,
        y: -40,
        ease: Power3.ease,
        delay: 1.0
      }
    )

  }, [])



  return (
    <div className='mt-5'>
      <section className='abotus_first_section text-center'>
        <div className='aboutus_found_year_title'  ref={el => { title1 = el }} style={{ opacity: '0' }}>ESTD 2023</div>
        <div className='about_story_title'  ref={el => { title2 = el }} style={{ opacity: '0' }}>The Story of our IT</div>
        <div className='about_solution_title'  ref={el => { title3 = el }} style={{ opacity: '0' }}>Solutions Firm</div>
      </section>

      <section></section>

      <section className='about_third_section'>
        <div className='about_thiord_side_title'>BEGINNING-</div>
        <div className='about_third_section2'>
          <div className='about_third_humble_title'>Humble Beginnings</div>
          <div className=' row about_third_imag_div'>
            <div className='col-12 col-sm-5'>
              <img className='about_main_img' alt='aboutimg' src={aboutimageone}></img>
            </div>
            <div className='col-12 col-sm-5'>
              <div className='about_third_journey_title'>
                We are on a journey to grow and help grow in this constantly altering world
              </div>
              <p className='about_third_para_one'>we find ourselves to have taken a lead and entered this specialisation with existing knowledge and suitable tools that help us triumph with services that we’ve spent time polishing for our prospects. Company is a platform that is developed to face the ever-growing world of technology that is focused on creating expert solutions.</p>
              <p className='about_third_para_two'>We are a team of multiple departments that is able to draw efficient results from our projects. We are composed of designers, engineers and talented minds that are concentrated on working professionally.</p>
              <div className='about_contact_title'
                onClick={() => {
                  navigate('/contact')
                }}>Contact<i class="fa-solid fa-arrow-up"></i></div>
            </div>
          </div>
        </div>
      </section>

      <section className='about_fourth_section'>
        <div>
          <img className='abot_logo_size' alt='mainlogowithtitle' src={mainlogowithtitle}></img>
        </div>
        <div className='about_thi_is_title'>“This is where<br /><span>Solutions are born”</span></div>
      </section>

      <section className='about_fifth_section '>
        <div className='about_fifth_section_second_div'>
          <div className='row'>
            <div className='col-12 col-sm-6 text-start'>
              <div className='about_our_title'>Our<br /> Mission</div>
            </div>
            <div className='col-12 col-sm-6 text-start'>
              <div className='about_our_right_title'>Our Sole<span> Mission</span> and<br />Purpose</div>
              <p className='about_paragraph'>We run with a simple goal in mind, which is working for the best with our clients with a specialised task force so that we can create a high-quality relationship between both parties, us and you.</p>
              <p className='about_paragraph'>Upon establishing the firm we took up the objective to treat our consumers with the utmost respect, professionalism, and care. We work on creating a reliable, performance-oriented space to respect the parties that we work with. We have also taken up feedback assurance to improve at every step made by us. This will only help us be better in future.</p>
            </div>
          </div>

          <div className='row' style={{ marginTop: '40px', position: 'relative' }}>
            {/* <div className='about_mission_side_title'>MISSION-</div> */}
            <div className='col-12 col-sm-6 text-start'>
              <div className='about_our_title'>Our<br /> Vision</div>
            </div>
            <div className='col-12 col-sm-6 text-start'>
              <div className='about_our_right_title'>Limitless <span> Growth</span></div>
              <p className='about_paragraph'>As a company that aims to grow in this era. We’ve set goals that we would like to achieve along with you. Our aim is to excel and be among the top IT solution providers. We focus on collaborating with you and adapting according to your project and setting a dignified example to work great with each prospect. We lead your way to success. We are able to integrate our force in your favour to augment the methods of working to be flexible and great at our work.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='about_us_sixth_section'>
        <Marquee speed={400}  >
          <span className='home_fifth_text_name'>Integration</span>
          <span className='home_fifth_text_name'>Scalability</span>
          <span className='home_fifth_text_name'>User</span>
        </Marquee>
      </section>

      <section className='aboutus_seven_section pb-5'>
        <div className='aboutus_seven_section_second_div'>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <div className='about_our_title'>Meet Our Team</div>
            </div>
            <div className='col-12 col-sm-6'>
              <p className='about_paragraph'>When we say that our team is composed of a task that is dignified at their work, we mean it. The leaders of our various groups are presented along with the members that actively take part in projects that are best suited to their skill set. This organization allows us to concentrate on things that need active participation and work to be able to bring the best of the visions to a reality that each individual wants with their plans.</p>
            </div>

          </div>

        </div>

      </section>

      <GoToTop />
    </div>
  )
}

export default Aboutus;