import React from 'react';
import './Footer.css';
import mainlogo from '../../Images/mainlogo.png';
import { Navigate, useNavigate } from 'react-router-dom';

function Footer() {

  let Navigate = useNavigate();

  return (
    <div>


      <div className='footer_main_div'>
        <section className='footer_first_section'>
          <div className='text-center'>
            <div className='footer_tellus_title'>
              Tell us about your project
            </div>
            <div className='footer_letswork_title'>Let's Work Together</div>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='footer_callus_title'>Call Us</div>
              <div className='footer_red_arrow'><i class="fa-solid fa-arrow-up fa-2xl" style={{ color: 'white' }}></i>
              </div>
            </div>
          </div>
        </section>
        <section className='footer_second_section'>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-2 col-sm-12 col-12 text-start">
                <img className='footer_logo' src={mainlogo} alt='Kodenauts'></img>
              </div>

              <div class="col-md-8 col-sm-12 col-12 text-start footer_second">
                <div>
                  <span className='footer_main_title'><a className='footer_main_title' href='mailto:contact@kodenauts.com'>contact@kodenauts.com</a> - <a href='tel:(+91)7383977771' className='footer_main_title'>+917383977771</a></span>
                  <div>
                    <ul className='footer_links'>
                      <li
                        onClick={() => {
                          Navigate('/aboutus')
                        }}>About us</li>
                      <li
                        onClick={() => {
                          Navigate('/services')
                        }}>Services</li>
                      <li
                        onClick={() => {
                          Navigate('/contact')
                        }}>Contact Us</li>
                      <li>Blogs</li>
                      <li>Work</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-sm-12 col-12">
                <ul className='footer_right_icons_link'>
                  <li>In</li>
                  <li>tw</li>
                  <li>pi</li>
                </ul>
              </div>

            </div>
          </div>
        </section>

        <section className='policy_main_div'>
           <span onClick={()=>{
            Navigate('/privacy-policy')
           }}>privacy-policy</span>
           
           <span onClick={()=>{
            Navigate('/terms-condition')
           }}>terms-condition</span>

           <span onClick={()=>{
            Navigate('/cancellation-Refund')
           }}>cancellation-Refund</span>

           <span onClick={()=>{
            Navigate('/shipping-delivery')
           }}>shipping-delivery</span>
        
        </section>

      </div>
    </div>
  )
}

export default Footer;