import './App.css';
import Homepage from './Components/Homepage/Homepage';
import { Route, Routes } from 'react-router-dom';
import User from './Components/User';
import Contact from './Components/Contact/Contact';
import Aboutus from './Components/Aboutus/Aboutus';
import Services from './Components/Services/Services';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import './locomotive-scroll.css';

import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useRef, useState, useEffect } from 'react';
import Headertwo from './Components/Headertwo';

import SmoothScroll from "./Components/SmoothScroll";


import AnimatedCursor from "react-animated-cursor"
import Privacyandpolicy from './Components/Privacyandpolicy';
import Tandc from './Components/Tandc';
import Canandrefund from './Components/Canandrefund';
import Shippinganddel from './Components/Shippinganddel';




function App() {




  useEffect(() => {
    const loader = document.querySelector("#loader");

    const timeoutId = setTimeout(() => {
      loader.style.top = '-100%';
    }, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  // const ref = useRef(null);

  // const options = {
  //   smooth: true,
  // }


  // const [visible, setVisible] = useState(false)

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300) {
  //     setVisible(true)
  //   }
  //   else if (scrolled <= 300) {
  //     setVisible(false)
  //   }
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // };

  // window.addEventListener('scroll', toggleVisible);




  return (
    <>
      {/* <LocomotiveScrollProvider options={options} containerRef={ref}>
        <main data-scroll-container ref={ref}>
          <Headertwo />
          <main data-scroll-section>
            <Routes>
              <Route path='/' element={<Homepage />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/aboutus' element={<Aboutus />} />
              <Route path='/services' element={<Services />} />
            </Routes>
          </main>
          <Footer />
        </main>
      </LocomotiveScrollProvider> */}

      <div id="loader">
        <h1>Think</h1>
        <h1>Create</h1>
        <h1>Productiz</h1>
      </div>

      <Headertwo />
      <SmoothScroll>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/aboutus' element={<Aboutus />} />
          <Route path='/services' element={<Services />} />
          <Route path='/privacy-policy' element={<Privacyandpolicy />} />
          <Route path='/terms-condition' element={<Tandc />} />
          <Route path='/cancellation-Refund' element={<Canandrefund />} />
          <Route path='/shipping-delivery' element={<Shippinganddel />} />
        </Routes>
        <Footer />
      </SmoothScroll>

      {/* <AnimatedCursor
        innerSize={8}
        outerSize={8}
        color='193, 11, 111'
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={20}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link',
          {
            target: '.custom',
            options: {
              innerSize: 12,
              outerSize: 12,
              color: '255, 255, 255',
              outerAlpha: 0.3,
              innerScale: 0.7,
              outerScale: 5
            }
          }
        ]} /> */}




    </>
  );
}

export default App;
