import React from 'react';
import './Contact.css';
import GoToTop from '../ScrollToTop';
import Marquee from "react-fast-marquee";

function Contact() {
  return (
    <div className=''>





      <section className='contact_section_one'>

        <div className='contact_first_section'>
          <Marquee speed={400}   >
            <span className='home_fifth_text_name'>Come Find Us.</span>
            <span className='home_fifth_text_name'>Come Find Us.</span>
            <span className='home_fifth_text_name'>Come Find Us.</span>
          </Marquee>
        </div>


        <div className='map_main_div'>
          <iframe class="footer_map_image" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14765.859168226358!2d73.2477573!3d22.298255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbac18ca60b8767e!2sNysha%20Solutions!5e0!3m2!1sen!2sin!4v1668751651502!5m2!1sen!2sin" width="350px" height="250px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </section>

      <section className='contact_section_second'>
        <div>
          <div className='contact_write_title'>Write <div>&nbsp;to us</div></div>
          <form style={{ padding: '0px 20px' }}>
            <div className='contact_form_div'>
              <input type="email" class="form-control bootstarp_form" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='First Name' />
              <input type="email" class="form-control bootstarp_form" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Last Name' />
            </div>
            <div className='contact_form_div2'>
              <input type="email" class="form-control bootstarp_form" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email' />
              <input type="email" class="form-control bootstarp_form" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Phone Number' />
            </div>
            <div className='contact_form_div3'>
              <textarea class="form-control bootstarp_placeholder" id="exampleFormControlTextarea1" rows="9" placeholder='Message'></textarea>
            </div>
            <div className='d-flex justify-content-center'>
              <div className='contact_send_btn'><i class="fa-solid fa-arrow-right fa-2xl" style={{ color: 'white' }}></i></div>
            </div>
          </form>
        </div>
      </section>
      <GoToTop />
    </div>
  )
}

export default Contact